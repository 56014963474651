<template>
  <div>
    <SimpleTable ref="table" :tableProps="tableProps" :queryFun="queryFun">
      <template slot="left-r">
        <el-button
          class="add-btn"
          icon="el-icon-circle-plus-outline"
          @click="toDetail(true)"
          >添加</el-button
        >
      </template>
      <template slot="right-r" slot-scope="{ data }"
        ><el-button
          icon="el-icon-upload2"
          class="export-btn"
          @click="exportData(data.params)"
          >导出</el-button
        ></template
      >
      <div slot="action" slot-scope="{ data }">
        <el-button type="text" @click="toDetail(false, data.row)"
          >查看详情</el-button
        >
        <el-button
          v-if="data.row.signInStatus != 1"
          type="text"
          @click="toDetail(true, data.row)"
          >编辑</el-button
        >
        <el-button @click="deleteServiceItem(data.row)" type="text"
          >删除</el-button
        >
      </div>
      <template slot="policyNumber" slot-scope="{ data }">
        <el-button type="text">{{ data.row.policyNumber }}</el-button>
      </template>
      <template slot="reportStatus" slot-scope="{ data }">
        <div :style="sdReportStatus[data.row.reportStatus || 1].style">
          {{ sdReportStatus[data.row.reportStatus || 1].name }}
        </div>
      </template>
      <template slot="serviceStatus" slot-scope="{ data }">
        <div :style="sdServerStatus[data.row.serviceStatus || 1].style">
          {{ sdServerStatus[data.row.serviceStatus || 1].name }}
        </div>
      </template>
    </SimpleTable>
  </div>
</template>

<script>
import SimpleTable from "@/components/SimpleTable";
import { dictionaryBatch } from "@/api/policy";
import { sdServerStatus, sdReportStatus } from "@/const/policyConst";
import {
  getServiceList,
  deleteService,
  exportService,
} from "@/api/safeDuty.js";
export default {
  components: { SimpleTable },
  data() {
    return {
      sdServerStatus: sdServerStatus,
      sdReportStatus: sdReportStatus,
      dictList: {},
      tableProps: {
        fuzzyQueryKey: "fuzzySearch",
        currentPageKey: "current",
        currentSizeKey: "size",
        totalKey: "total",
        height: 600,
        searchConfigs: [],
        columnProps: [
          {
            prop: "",
            label: "序号",
            width: "55",
            type: "index",
            isShow: true,
          },
          {
            prop: "serviceNo",
            label: "服务编号",
            width: "",
            type: "",
            isShow: true,
          },

          {
            prop: "policyNo",
            label: "保单号",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "insuredName",
            label: "被保险人",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "projectName",
            label: "所属项目",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "serviceTypesOfValue",
            label: "服务类型",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "serviceProjectName",
            label: "服务项目",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "designateExpert",
            label: "服务专家",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "serviceStatus",
            label: "服务状态",
            width: "",
            type: "",
            slot: true,
            isShow: true,
          },
          {
            prop: "reportStatus",
            label: "报告状态",
            width: "",
            type: "",
            slot: true,
            isShow: true,
          },
          // {
          //   prop: "serviceManagerName",
          //   label: "服务负责人",
          //   width: "",
          //   type: "",
          //   isShow: true,
          // },
          {
            prop: "signInTime",
            label: "服务时间",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "createTime",
            label: "录入时间",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "action",
            label: "操作",
            width: "150",
            type: "",
            slot: true,
            isShow: true,
          },
        ],
      },
    };
  },
  created() {
    this.getDictionarySd();
  },
  methods: {
    getDictionarySd() {
      let dictKeys = ["ServiceTypesOf"];
      dictionaryBatch({
        codes: dictKeys.join(","),
      }).then((res) => {
        if (res && res.data) {
          dictKeys.forEach((key) => {
            this.$set(this.dictList, key, res.data[key] || []);
          });
        }
        this.setConfig();
      });
    },
    setConfig() {
      this.tableProps.searchConfigs = [
        {
          label: "服务编号",
          type: "input",
          modelKey: "serviceNo",
        },
        {
          label: "保单号",
          type: "input",
          modelKey: "policyNo",
        },
        {
          label: "所属项目",
          type: "input",
          modelKey: "projectName",
        },
        {
          label: "服务类型",
          type: "select",
          modelKey: "serviceTypesOfKey",
          modelTextKey: "serviceTypesOf",
          option: this.dictList.ServiceTypesOf,
        },
        {
          label: "被保险人",
          type: "input",
          modelKey: "insuredName",
        },
        {
          label: "服务负责人",
          type: "input",
          modelKey: "serviceManagerName",
        },
        {
          label: "服务时间",
          type: "daterange",
          daterangeKeys: ["serviceDateBegin", "serviceDateEnd"],
          modelKey: "serviceDate",
        },
        {
          label: "录入时间",
          type: "daterange",
          daterangeKeys: ["createTimeBegin", "createTimeEnd"],
          modelKey: "createTime",
        },
      ];
    },
    toDetail(edit = false, item = {}) {
      this.$router.push({
        name: "safeDutyServiceManagementDetail",
        params: {
          id: item?.id,
          isEdit: edit,
        },
      });
    },
    deleteServiceItem(item) {
      this.$confirm("删除后对应数据也将清理，是否确认删除？", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteService({ id: item.id }).then(() => {
          this.$refs.table.getData();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
      });
    },
    //导出数据
    exportData(data) {
      exportService(data);
    },
    async queryFun(queryParams) {
      let res = await getServiceList(queryParams);
      return {
        localData: res.data.records || [{}],
        total: res.data.total,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.add-btn {
  background: #00bc0d;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  border-color: #00bc0d;
}
.dele-btn {
  background: #c94242;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  border-color: #c94242;
}
.export-btn {
  background: #4278c9;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  border-color: #4278c9;
}
</style>
