export const sdPolicyStatus = {
  未起保: {
    style: {
      fontWeight: "400",
      fontSize: "14px",
      color: "#FCA118",
      background: "rgba(252,161,24,0.1)",
      borderRadius: "2px 2px 2px 2px",
      border: "1px solid rgba(252,161,24,0.2)",
      display: "inline-block",
      padding: "5px 12px",
    },
  },
  保障中: {
    style: {
      fontWeight: "400",
      fontSize: "14px",
      color: "#3470FF",
      background: " rgba(52,112,255,0.1)",
      borderRadius: "2px 2px 2px 2px",
      border: "1px solid rgba(52,112,255,0.2)",
      display: "inline-block",
      padding: "5px 12px",
    },
  },
  已过期: {
    style: {
      fontWeight: "400",
      fontSize: "14px",
      color: "#CCCCCC",
      background: "rgba(204,204,204,0.1)",
      borderRadius: "2px 2px 2px 2px",
      border: "1px solid rgba(204,204,204,0.2)",
      display: "inline-block",
      padding: "5px 12px",
    },
  },
};

export const sdReportStatus = {
  1: {
    name: "未提交",
    style: {
      fontWeight: "400",
      fontSize: "14px",
      color: "#CCCCCC",
      background: "rgba(204,204,204,0.1)",
      borderRadius: "2px 2px 2px 2px",
      border: "1px solid rgba(204,204,204,0.2)",
      display: "inline-block",
      padding: "5px 12px",
    },
  },
  2: {
    name: "待审核",
    style: {
      fontWeight: "400",
      fontSize: "14px",
      color: "#FCA118",
      background: "rgba(252,161,24,0.1)",
      borderRadius: "2px 2px 2px 2px",
      border: "1px solid rgba(252,161,24,0.2)",
      display: "inline-block",
      padding: "5px 12px",
    },
  },
  3: {
    name: "审核退回",
    style: {
      fontWeight: "400",
      fontSize: "14px",
      color: "#F64A2D",
      background: " rgba(246,74,45,0.1)",
      borderRadius: "2px 2px 2px 2px",
      border: "1px solid rgba(246,74,45,0.2)",
      display: "inline-block",
      padding: "5px 12px",
    },
  },
  4: {
    name: "已通过",
    style: {
      fontWeight: "400",
      fontSize: "14px",
      color: "#3470FF",
      background: " rgba(52,112,255,0.1)",
      borderRadius: "2px 2px 2px 2px",
      border: "1px solid rgba(52,112,255,0.2)",
      display: "inline-block",
      padding: "5px 12px",
    },
  },
};

export const sdServerStatus = {
  1: {
    name: "待跟进",
    style: {
      fontWeight: "400",
      fontSize: "14px",
      color: "#CCCCCC",
      background: "rgba(204,204,204,0.1)",
      borderRadius: "2px 2px 2px 2px",
      border: "1px solid rgba(204,204,204,0.2)",
      display: "inline-block",
      padding: "5px 12px",
    },
  },
  2: {
    name: "跟进中",
    style: {
      fontWeight: "400",
      fontSize: "14px",
      color: "#FCA118",
      background: "rgba(252,161,24,0.1)",
      borderRadius: "2px 2px 2px 2px",
      border: "1px solid rgba(252,161,24,0.2)",
      display: "inline-block",
      padding: "5px 12px",
    },
  },
  3: {
    name: "已完成",
    style: {
      fontWeight: "400",
      fontSize: "14px",
      color: "#3470FF",
      background: " rgba(52,112,255,0.1)",
      borderRadius: "2px 2px 2px 2px",
      border: "1px solid rgba(52,112,255,0.2)",
      display: "inline-block",
      padding: "5px 12px",
    },
  },
};
